import _styled, { default as _styled2, default as _styled3, default as _styled4, default as _styled5, default as _styled6, default as _styled7 } from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import * as zod from "zod";
import CpError from "components/common/CpError";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import { SessionType } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useFormResolver from "hooks/useFormResolver";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { useIntl } from "react-intl";
import { useMutation } from "react-relay";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
import { patientSupportProgramSessionMutation } from "./Psp";
const localStyles = {
  buttonContainer: css`
    ${styles.rowAndCenter};
    justify-content: space-between;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  heading: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
/**
 * Form for a partner PSP program that requests a patient ID for input
 */
const FmPartnerPspPatientId = ({
  partner,
  patientIdMaxLength,
  patientType,
  validatorRegex
}) => {
  const navigation = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const handleCancel = useCallback(() => navigation.navigate(Routes.home), [navigation]);
  const handleNavigateToPatientSession = useCallback((patientSessionId, patientId) => {
    trackGoogleEvent({
      action: "Started",
      name: "pspSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.patientSessionId]: patientSessionId,
          [GoogleAnalyticsDimension.patientSessionType]: SessionType.PspSession
        }
      }
    });
    navigation.navigate(Routes.view_session, {
      patientId,
      patientSessionId
    });
  }, [navigation, trackGoogleEvent]);
  const [handlePspMutation, isSubmittingHandlePspMutation] = useMutation(patientSupportProgramSessionMutation);
  const [formError, setFormError] = useState();
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      // handle the correct validation -- AZ users must match "FSN" + 7 digits format
      patientId: zod.string().max(patientIdMaxLength).regex(validatorRegex, {
        message: formatMessage(translations.pspTranslations.invalidId)
      })
    }), [formatMessage, patientIdMaxLength, validatorRegex]))
  });
  const handleSubmit = useMemo(() => {
    return form.handleSubmit(async values => {
      try {
        setFormError(undefined);
        handlePspMutation({
          onCompleted: ({
            patientSupportProgramSessionCreate
          }) => {
            Log.info(`FmPartnerPspPatientId - PSP Created/Fetched: ${patientSupportProgramSessionCreate?.patientSession?._id}`);
            if (patientSupportProgramSessionCreate?.patientSession?._id && patientSupportProgramSessionCreate?.patientSession?.patientId) {
              handleNavigateToPatientSession(patientSupportProgramSessionCreate.patientSession._id, patientSupportProgramSessionCreate.patientSession.patientId);
            }
          },
          onError: err => {
            Log.error(`FmPartnerPspPatientId - ${err}`);
            setFormError(err);
          },
          variables: {
            input: {
              identifier: values.patientId,
              routeParam: partner,
              type: patientType
            }
          }
        });
      } catch (err) {
        Log.error(`FmPartnerPspPatientId - ${err}`);
        if (err instanceof Error) {
          setFormError(err);
        }
      }
    });
  }, [form, handleNavigateToPatientSession, handlePspMutation, partner, patientType]);
  return <CpForm onSubmit={handleSubmit} {...form}>
      <_StyledPrText children={translations.pspTranslations.patientInfo} $_css={localStyles.heading} />
      <CpFormTextInput autoCapitalize="none" autoCorrect={false} keyboardType="default" label={translations.pspTranslations.patientId} maxLength={10} name="patientId" testID="FmPartnerPspPatientId-patientId" textContentType="none" />
      <_StyledCpError error={formError} $_css2={[styles.formField, styles.errorBox]} />
      <_StyledView $_css3={!isNarrowScreen ? localStyles.buttonContainer : undefined}>
        <_StyledPrButton busy={isSubmittingHandlePspMutation} disabled={isSubmittingHandlePspMutation} onPress={handleSubmit} testID="FmPartnerPspPatientId-StartProgramButton" $_css4={styles.formField}>
          <_StyledPrText2 children={translations.pspTranslations.startProgram} $_css5={localStyles.buttonText} />
        </_StyledPrButton>
        <_StyledPrButton2 onPress={handleCancel} testID="FmPartnerPspPatientId-CancelButton" type="text" $_css6={styles.formField}>
          <_StyledPrText3 children={translations.buttons.cancel} $_css7={localStyles.buttonText} />
        </_StyledPrButton2>
      </_StyledView>
    </CpForm>;
};
export default FmPartnerPspPatientId;
var _StyledPrText = _styled(PrText)`${p => p.$_css}`;
var _StyledCpError = _styled2(CpError)`${p => p.$_css2}`;
var _StyledView = _styled3(View)`${p => p.$_css3}`;
var _StyledPrButton = _styled4(PrButton)`${p => p.$_css4}`;
var _StyledPrText2 = _styled5(PrText)`${p => p.$_css5}`;
var _StyledPrButton2 = _styled6(PrButton)`${p => p.$_css6}`;
var _StyledPrText3 = _styled7(PrText).withConfig({
  displayName: "FmPartnerPspPatientId___StyledPrText3",
  componentId: "sc-15cylqd-0"
})(["", ""], p => p.$_css7);