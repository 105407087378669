import _styled, { default as _styled2, default as _styled3 } from "styled-components";
import React from "react";
import { useIntl } from "react-intl";
import { Image, View } from "react-native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import FmPartnerPspPatientId from "components/psp/FmPartnerPspPatientId";
import { Partner, PatientType } from "components/psp/Psp";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
const tezspireLogoUrl = "https://assets.imdhealth.app/images/tezspire-partner-logo.png";
// Logo width is ~3.4x the logo height
const LOGO_HEIGHT = 80;
const LOGO_WIDTH = 272;
const localStyles = {
  image: css`
    height: 100%;
    width: 100%;
    align-self: center;
  `,
  imageContainer: css`
    align-self: center;
    /* Image logo has transparent background, forcing "white" here to ensure visibility */
    background-color: white;
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    height: ${({
    theme
  }) => theme.spacing.double + LOGO_HEIGHT}px;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.double}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    width: ${({
    theme
  }) => theme.spacing.double + LOGO_WIDTH}px;
  `
};

/**
 * Tezspire partner login page
 */
const CpTezspire = () => {
  const {
    isMediumOrSmallerScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  return <>
      <CpUpdatePageTitle title="Tezspire" />
      <CpBackgroundHeader childStyle={styles.flexSingle} header={formatMessage(translations.labels.patientSupportPrograms, {
      partner: "Tezspire"
    })}>
        <_StyledView $_css={[styles.paddingDouble, !isMediumOrSmallerScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledView2 $_css2={localStyles.imageContainer}>
            <_StyledImage accessibilityRole="image" resizeMode="contain" source={{
            uri: tezspireLogoUrl
          }} $_css3={localStyles.image} />
          </_StyledView2>

          {/* Sign In Form */}
          <FmPartnerPspPatientId partner={Partner.Tezspire} patientIdMaxLength={10} patientType={PatientType.Tezspire} validatorRegex={/TEZ\d{7}/i} />
        </_StyledView>
      </CpBackgroundHeader>
    </>;
};
export default CpTezspire;
var _StyledView = _styled(View)`${p => p.$_css}`;
var _StyledView2 = _styled2(View)`${p => p.$_css2}`;
var _StyledImage = _styled3(Image).withConfig({
  displayName: "CpTezspire___StyledImage",
  componentId: "sc-1gc5svp-0"
})(["", ""], p => p.$_css3);